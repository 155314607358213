// ==================================================
// Globals - Forms
// ==================================================
//
// form {
//
// 	legend+p {
// 		line-height: 1.2;
// 	}
//
// 	legend+ul, legend+ol {
// 		padding-top: 1em;
// 	}
//
// 	ol, ul {
// 		margin: 0;
//
// 		li {
// 			list-style: none;
// 		}
// 	}
//
// }
//
// .req label {
// 	background: none;
// 	&:after {
// 		content: " *";
// 		color: red;
// 	}
// }
//
// p.required {
// 	display: inline-block;
// 	margin-bottom: 1em !important;
// 	background: none;
//
// 	&:before {
// 		content: "* ";
// 		color: red;
// 	}
// }
//
//
// .req ins {
// 	position: absolute;
// 	left: -9999px;
// }
