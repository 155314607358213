.mura-index {
	.comments {}
	.credits {
		// undo color
	}
	.releaseDate {
		font-weight: normal;
		margin: 0;
	}
	.tags{}
}



// ==================================================
// search results
// ==================================================

.mura-search-results {
	.moreResults {
		background: none;
		border: 0;
		padding: 0;
		margin: 0;
	}
}
