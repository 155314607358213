// ==================================================
// search results
// ==================================================

.mura-search-results {
	.mura-index {}
	.mura-search-again {}

	&__matches {
		p {
			margin-bottom: 0;
		}
	}
}



// ==================================================
// more results
// ==================================================
.moreResults {
	border-radius: 6px;

	dt {
		font-size: 12px;
		font-weight: normal;
	}

	> p {
		margin: 0.5em 0;
	}

	> ul {
		whitespace: nowrap;

		> li {
			display: inline-block;

			> a {
				&:hover {
					background-color: $gray-200;
					color: #fff;
				}
			}
		}
	}

	> ul.pager {
		margin: 0;
	}

}
