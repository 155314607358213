.carousel {}

.carousel-item {
	max-height: 628px;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,.1);
	}

	a {
		color: #fff;
	}
}

.carousel-caption {
	background-color: rgba(0,0,0,.3);
	left: 0;
	right: 0;
	bottom: 0;
}
