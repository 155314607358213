// ==================================================
// Header
// ==================================================

.navbar-nav {
	.nav-item {
		&:hover {
			> .dropdown-menu {
				display: block;
			}
		}

		.dropdown-menu {
			margin: 0;
		}
	}
}
