@mixin fa {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icon-before {	// For icons added immediately before the element targeted
	margin-right: .25em;
	color: $brand-primary;
}
