// COMMENTS

.mura-comments {
	> .mura-comment-sort-container,
	.mura-comment-more-comments-container {
		margin-bottom: 1rem;
	}

	dl:last-child {
		border-bottom: 0;
		padding-bottom: 0;
	}

	dt > em {
		font-weight: normal;
		font-style: italic;
	}

	#mura-comment-post-comment legend {
		border: 0;
	}

	dd {

		&.mura-comment-reply {
			@include fa;

			&:before {
			  @include icon-before;
			  content: "\f112";
			}
		}

		&.mura-comment-spam {
			@include fa;

			&:before {
			  @include icon-before;
			  content: "\f05e";
			}
		}

		&.mura-comment-post-reply {
		}


	}
}
