@import "material-colors";

// Variables
// --------------------------------------------------
$brand-primary:								#f00;

// $theme-colors: (
//   primary: $brand-primary,
//   secondary: $gray-600,
//   success: $green,
//   info: $cyan,
//   warning: $yellow,
//   danger: $red,
//   light: $gray-100,
//   dark: $gray-800
// ) !default;

// Global values
// --------------------------------------------------
